<template>
  <div style="width: 100%">
    <div class="container">
      <b-table
        :busy="isBusy"
        ref="table"
        :filter="search"
        :current-page="currentPage"
        :per-page="perPage"
        :tbody-tr-class="'transparentTr'"
        :fields="fields"
        :items="itemsProvider"
        show-empty
      >
        <template #empty>
          <div
            v-if="processing"
            style="color: #55904e"
            class="text-center my-2"
          >
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <div class="text-center" v-else>No Properties Found</div>
        </template>
        <template #table-busy>
          <div style="color: #55904e" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(custom)="row">
          <simple-list-property
            @componentMessages="messageStatusCheck($event)"
            @componentOffers="offerStatusCheck($event)"
            :offerTypes="offerTypes"
            :openCreateBox="openCreateBox[row.item.id]"
            :key="row.item.id"
            :property="row.item"
            :show="show[row.item.id]"
            :currentComponent="currentComponent"
            :offers="offers"
            :viewings="viewings"
            :fromBuying="true"
          >
            <template #descriptionTitle="{ property }">
              <span
                style="text-align: justify; margin-top: 2px !important"
                class="mt-n4"
              >
                {{
                  property.description
                    ? property.description[0]
                      ? `${
                          property.description[0].value[
                            Object.keys(property.description[0].value)[0]
                          ]
                        }`
                      : ""
                    : ""
                }}<router-link
                  v-if="
                    (property.description
                      ? property.description[0]
                        ? property.description[0].value[
                            Object.keys(property.description[0].value)[0]
                          ]
                        : ''
                      : ''
                    ).length > 250
                  "
                  :to="`/property-details/${property.id}/?read-more`"
                  >...more</router-link
                ></span
              >
            </template>
            <template #removeFromBuying>
              <img
                style="height: 30px; width: 30px"
                @click="$refs[`deleteModal${row.item.id}`].show()"
                :class="[
                  'w-30',
                  'margin-r-5',
                  'res-w30',
                  _.get(
                    row.item.propertyPackage,
                    `[${
                      _.defaultTo(
                        _.get(row.item.propertyPackage, 'length', null),
                        1
                      ) - 1
                    }].isPremiumListing`,
                    false
                  )
                    ? 'chat-delete'
                    : 'chat-white-filled',
                ]"
                :src="
                  require(`../assets/images/icons/Vencasa-Chat-Delete-${
                    _.get(
                      row.item.propertyPackage,
                      `[${
                        _.defaultTo(
                          _.get(row.item.propertyPackage, 'length', null),
                          1
                        ) - 1
                      }].isPremiumListing`,
                      false
                    )
                      ? 'White'
                      : 'Green'
                  }.webp`)
                "
              />
            </template>
            <template v-if="screenWidth >= 760" #buttons>
              <button
                v-if="row.item.status !== 2"
                @click="
                  findpropertyRelatedOffers(row.item.id).length
                    ? changeView('ListingTabsOffers', row.item.id)
                    : toggleBox(row, 'offer')
                "
                style="
                  width: 25%;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
                class="notification res-button-how"
              >
                Offers
                <span v-if="isNewOffer(row.item)" class="badge">1</span>
              </button>
              <button style="
                  width: 25%;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
                class="notification res-button-how" @click="changeView('ListingTabsSolicitor', row.item.id)" v-else>
                Solicitor Details
              </button>
              <button
                @click="
                  findAnyMessages(row.item)
                    ? changeView('ListingTabsMessages', row.item.id)
                    : toggleBox(row, 'question')
                "
                style="
                  width: 25%;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
                class="notification res-button-how"
              >
                Messages
                <span v-if="anyUnreadMessage(row.item)" class="badge">1</span>
              </button>
            </template>
            <template v-if="screenWidth <= 760" #buttonsAlt>
              <button
                v-if="row.item.status !== 2"
                @click="
                  findpropertyRelatedOffers(row.item.id).length
                    ? changeView('ListingTabsOffers', row.item.id)
                    : toggleBox(row, 'offer')
                "
                style="
                  width: 100%;
                  margin-top: 5px;
                  margin-bottom: 15px;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
                class="notification res-button-how"
              >
                Offers
                <span v-if="isNewOffer(row.item)" class="badge">1</span>
              </button>
              <button style="
                  width: 100%;
                  margin-top: 5px;
                  margin-bottom: 15px;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
                class="notification res-button-how" @click="changeView('ListingTabsSolicitor', row.item.id)" v-else>
                Solicitor Details
              </button>
              <button
                @click="
                  findAnyMessages(row.item)
                    ? changeView('ListingTabsMessages', row.item.id)
                    : toggleBox(row, 'question')
                "
                style="
                  width: 100%;
                  margin-top: 5px;
                  margin-bottom: 15px;
                  padding: 10px 0px;
                  border: none;
                  background-color: #618c57;
                  color: #fff;
                  border-radius: 5px;
                  font-size: 15px;
                "
                class="notification res-button-how"
              >
                Messages
                <span v-if="anyUnreadMessage(row.item)" class="badge">1</span>
              </button>
            </template>
          </simple-list-property>
          <b-modal
          @shown="setHeight(row.item.id)"
            centered
            :ref="`deleteModal${row.item.id}`"
            :id="`deleteModal${row.item.id}`"
            title="Alert"
          >
            <p>Are you sure you want to remove this listing?</p>
            <template #modal-footer>
              <b-button
                @click="$refs[`deleteModal${row.item.id}`].hide()"
                variant="secondary"
                size="md"
                class="listing-button-color-secondary btn btn-primary"
              >
                Cancel
              </b-button>
              <b-button
                @click="deleteList(row.item.id)"
                variant="primary"
                size="md"
                class="listing-button-color btn btn-primary"
              >
                Remove
              </b-button>
            </template>
          </b-modal>
          <b-modal
            :ref="`edit-offer-modal${row.item.id}`"
            centered
            title="Update Offer"
          >
            <div class="d-flex flex-column align-items-center">
              <p>Please enter your offer to Mrs Jones Offer of:</p>
              <strong
                ><p>
                  {{
                    findpropertyRelatedOffers(row.item.id).length
                      ? `£ ${
                          findpropertyRelatedOffers(row.item.id)[0][
                            "offerAmount"
                          ]
                        }`
                      : "No Offers"
                  }}
                </p></strong
              >
              <input
                v-model="counterOffer"
                type="text"
                placeholder="Enter amount"
              />
              <p class="mt-2">A message with your offer will be sent</p>
            </div>
            <template #modal-footer>
              <div class="w-100">
                <b-button
                  variant="secondary"
                  size="sm"
                  class="float-left listing-button-color-secondary"
                >
                  Cancel
                </b-button>
                <b-button
                  @click="sendCounterOffer(row.item)"
                  variant="primary"
                  size="sm"
                  class="float-right listing-button-color"
                >
                  Send
                </b-button>
              </div>
            </template>
          </b-modal>
          <b-modal
            size="lg"
            centered
            :ref="`modal-enquire${row.item.id}`"
            :id="`modal-enquire${row.item.id}`"
            title="Edit Viewing"
          >
            <div class="container mt-3">
              <div class="d-flex justify-content-center align-items-center">
                <vue-cal
                  ref="vueCal"
                  :events="events"
                  editable-events
                  style="height: 300px"
                  :on-event-click="fillTimeSlots"
                >
                  <template v-slot:event="{ event }">
                    <small>
                      <p>{{ event.title }}</p>
                      <p>{{ convertDate(event.start) }}</p>
                      <p>{{ convertDate(event.end) }}</p>
                    </small>
                  </template>
                </vue-cal>
              </div>
              <div class="container d-flex mt-4">
                <label v-if="!Object.keys(selectedSlot).length"
                  >Click on a viewing slot to book your appointment</label
                >
                <div class="d-flex" v-else>
                  <label class="mt-1">Selected Slot:</label>
                  <p class="ml-3 mt-1">
                    {{
                      selectedSlot
                        ? `${changeDate(
                            selectedSlot.date
                          )} from ${removeSeconds(
                            selectedSlot.timeStart
                          )} to ${removeSeconds(selectedSlot.timeEnd)}`
                        : "No Slot Selected"
                    }}
                  </p>
                </div>
              </div>
            </div>
            <template #modal-footer>
              <div>
                <button
                  @click="$refs[`modal-enquire${row.item.id}`].hide()"
                  class="
                    btn btn-primary
                    listing-button-color-secondary
                    res-button-how
                  "
                >
                  Cancel
                </button>
                <button
                  @click="bookTimeSlot(row.item)"
                  class="btn btn-primary listing-button-color res-button-how"
                >
                  Send
                </button>
              </div>
            </template>
          </b-modal>
        </template>
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { formatter } from "../utils";
import moment from "moment";
const SimpleListProperty = () =>
  import(
    /* webpackChunkName: "Su1NemRbgn" */ "../components/simple-list-property"
  );
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      propertyOffers: {},
      propertyMessages: {},
      screenWidth: window.innerWidth,
      offerTypes: [],
      openCreateBox: {},
      offerType: "",
      offerAmount: "",
      events: [],
      selectedSlot: {},
      property: {},
      value: "",
      options: [],
      show: {},
      currentComponent: "",
      search: "",
      counterOffer: "",
      isSortDesc: true,
      sortOrder: "desc",
      sortItem: "id",
      isBusy: false,
      currentPage: 1,
      rows: 9,
      perPage: this.showPerPage,
      fields: [
        {
          key: "custom",
          thClass: ["d-none"],
          tdClass: "remove-border",
        },
      ],
    };
  },
  watch: {
    sorting() {
      this.$refs.table.refresh();
    },
    showPerPage(val) {
      this.perPage = val;
    },
    properties(val) {
      this.rows = val.length;
      console.log(this.rows, "rows");
      this.$refs.table.refresh();
    },
  },
  computed: {
    _() {
      return _;
    },
    ...mapGetters({
      authUser: "auth",
    }),
  },
  mounted() {
    this.rows = this.properties.length;
    if (!this.offerTypes.length)
      this.$store
        .dispatch("getConfigurationsByKey", "offerType")
        .then((res) => {
          if (res.data.success) {
            this.offerTypes = Object.keys(res.data.data.val);
          }
        });
  },
  props: [
    "properties",
    "offers",
    "viewings",
    "processing",
    "sorting",
    "showPerPage",
  ],
  methods: {
    setHeight(propertyId){
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_content_`)
        .setAttribute(
          "style",
          "height : auto !important; display: block !important"
        );
      document
        .querySelector("#deleteModal" + propertyId)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_body_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_backdrop_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_outer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_header_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_footer_`)
        .setAttribute("style", "display: block !important");
      document
        .querySelector(`#deleteModal${propertyId}___BV_modal_title_`)
        .setAttribute("style", "display: block !important");
    },  
    offerStatusCheck(offerObject) {
      this.$set(
        this.propertyOffers,
        `${offerObject.property.id}`,
        offerObject.offers
      );
      console.log({offerObject})
      this.isNewOffer(offerObject.property);
    },
     messageStatusCheck(messageObject) {
       console.log("message status check", messageObject)
      this.$set(
        this.propertyMessages,
        `${messageObject.property.id}`,
        messageObject.messages
      );
      this.anyUnreadMessage(messageObject.property);
    },
    deleteList(id) {
      this.$store.dispatch("removeFromMyBuying", id).then(async (res) => {
        if (res == true) {
          this.$refs[`deleteModal${id}`].hide();
          this.$store.commit("setMessage", "Property removed from buying");
          await this.$store.dispatch("Property/getMyBuying");
          this.$refs.table.refresh();
        } else {
          this.$refs[`deleteModal${id}`].hide();
          this.$store.commit("setMessage", "Property not removed from buying");
        }
      });
    },
    toggleBox(row, type) {
      if (this.openCreateBox[row.item.id])
        this.$set(this.openCreateBox, `${row.item.id}`, {
          type: type,
          toggle: !this.openCreateBox[row.item.id]["toggle"],
        });
      else
        this.$set(this.openCreateBox, `${row.item.id}`, {
          type: type,
          toggle: true,
        });
    },
    formatter: formatter,
    findAnyMessages(property) {
      return property.messages.find(
        (message) =>
          message.messageGroupId ==
          `${property.id},${property.sellerId}-${this.authUser.buyer_seller_id}`
      );
    },
    removeSeconds(time) {
      return `${time.split(":")[0]}:${time.split(":")[1]}`;
    },
    isNewOffer(property) {
      let matchedOffer = (
        this.propertyOffers[`${property.id}`]
          ? this.propertyOffers[`${property.id}`]
          : property.offers
      ).filter((offer) => {
        return offer.isRead == 0 && offer.lastOfferBy !== this.authUser.buyer_seller_id && offer.offerGroupId ==
            `${property.id},${property.sellerId}-${this.authUser.buyer_seller_id}`;
      });
      return matchedOffer.length ? matchedOffer.length : false;
    },
    anyUnreadMessage(property) {
      let matchedMessage = (this.propertyMessages[`${property.id}`]
          ? this.propertyMessages[`${property.id}`]
          : property.messages).filter((message) => {
        return (
          message.status == "unread" &&
          message.messageGroupId ==
            `${property.id},${property.sellerId}-${this.authUser.buyer_seller_id}`
          &&
          message.userId !== this.authUser.buyer_seller_id
        );
      });
      return matchedMessage.length ? matchedMessage.length : false;
    },
    anyPendingViewings(property) {
      let matchedViewing = property.viewing.find((viewing) => {
        return viewing.status == "pending";
      });
      return matchedViewing ? true : false;
    },
    changeDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    convertDate(date) {
      return moment(date).format("hh:mm:ss a");
    },
    findpropertyRelatedOffers(propertyId) {
      return this.offers
        .filter((offer) => offer.propertyId == propertyId)
        .sort((a, b) => {
          if (a > b) return 1;
          else if (a < b) return -1;
          else return 0;
        });
    },
    dateClass(ymd, date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      let doesMatch = false;
      this.property.timeSlots.forEach((slot) => {
        if (slot.date == formatedDate) {
          doesMatch = true;
        }
      });
      return doesMatch ? "marked-cell" : "";
    },
    dateDisabled(ymd, date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      let doesMatch = true;
      this.property.timeSlots.forEach((slot) => {
        if (slot.date == formatedDate) {
          doesMatch = false;
        }
      });
      return doesMatch;
    },
    fillTimeSlots(event, e) {
      this.selectedSlot = JSON.parse(event.content);
      e.stopPropagation();
    },
    bookTimeSlot(property) {
      this.$store.commit("setShowLoader", true);
      const data = {
        propertyId: property.id,
        sellerTimeSlotId: this.selectedSlot.id,
        slotDate: this.selectedSlot.date,
        slotTime: moment(
          `${this.selectedSlot.date} ${this.selectedSlot.timeStart}`
        ).format("h:mm A"),
      };
      this.$store.dispatch("bookTimeSlot", data).then(async (res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          this.$refs[`modal-enquire${property.id}`].hide();
          this.$store.commit("setMessage", "Time Slot Booked Successfully!");
          await this.$store.dispatch("getMyBuying");
          await this.$store.dispatch("getMyOffers");
          await this.$store.dispatch("getMyViewing");
        } else {
          alert("error");
        }
      });
    },
    updateOffer(property) {
      this.$refs[`edit-offer-modal${property.id}`].show();
    },
    sendCounterOffer(property) {
      this.$store.commit("setShowLoader", true);
      const data = {
        propertyId: property.id,
        offerId: this.offers[0] ? this.offers[0].id : null,
        offerAmount: this.counterOffer,
        offerType: "cash",
      };
      this.$store.dispatch("createOffer", data).then(async (res) => {
        this.$store.commit("setShowLoader", false);
        if (res == true) {
          this.$refs[`edit-offer-modal${property.id}`].hide();
          this.$store.commit("setMessage", "Counter offer sent!");
          await this.$store.dispatch("getMyBuying");
          await this.$store.dispatch("getMyOffers");
          await this.$store.dispatch("getMyViewing");
        } else alert("counter offer not sent");
      });
    },
    updateViewing(property) {
      this.selectedSlot = {};
      this.property = property;
      this.$refs[`modal-enquire${property.id}`].show();
      setTimeout(() => {
        this.property.timeSlots.forEach((slot) => {
          let isViewingSlot = this.property.viewing.some(
            (view) =>
              view.sellerTimeSlotId == slot.id && view.status == "accept"
          );
          if (isViewingSlot) return;
          let dateStart = moment(`${slot.date} ${slot.timeStart}`);
          let dateEnd = moment(`${slot.date} ${slot.timeEnd}`);
        });
      }, 100);
    },
    changeView(view, id) {
      if (view != this.currentComponent) {
        this.currentComponent = view;
        for(let key in this.show){
          this.$set(this.show, `${key}`, false);
        }
        this.$set(this.show, `${id}`, true);
      } else {
        this.$set(this.show, `${id}`, !this.show[id]);
      }
    },
    itemsProvider(ctx, callback) {
      let offset = (this.currentPage - 1) * this.perPage;
      let items =
        _.orderBy(
          this.properties.slice(offset, this.perPage * this.currentPage),
          [this.sorting.split("|")[0]],
          [this.sorting.split("|")[1]]
        ) || [];
      this.$emit("total", items.length);
      return items;
    },
  },
  components: {
    SimpleListProperty,
  },
};
</script>

<style scoped src="../assets/css/fontawesome-all.css"></style>
<style scoped src="../assets/css/fontawesome-5-all.min.css"></style>
<style scoped src="../assets/css/font-awesome.min.css"></style>
<style scoped src="../assets/css/styles.css"></style>

<style scoped>
.margin-r-5 {
  margin-right: 5px;
}

@media (min-width: 300px) and (max-width: 700px) {
  .res-w30 {
    width: 25px !important;
    height: 25px !important;
  }
}

div >>> .vuecal__menu,
.vuecal__cell-events-count {
  background-color: #548f4d;
}

div >>> .page-item.active .page-link {
  background-color: #55904e;
  border-color: #55904e;
}
div >>> .page-link {
  color: #55904e;
}

div >>> .remove-border {
  border-top: none;
  padding: 0px;
}
.transparentTr {
  background-color: transparent !important;
}

.pending-hover:hover {
  cursor: pointer;
  color: red;
}

.chat-delete:hover {
  content: url("../assets/images/icons/Vencasa-Icons-Cross.webp");
}

.chat-white-filled:hover {
  content: url("../assets/images/icons/Vencasa-chat-delet-fill.webp");
}

div >>> .marked-cell {
  background-color: yellow;
}

.notification {
  background-color: #555;
  color: white;
  text-decoration: none;
  padding: 15px 26px;
  position: relative;
  display: inline-block;
  border-radius: 2px;
}

.notification:hover {
  background: red;
}

.w-30 {
  width: 30px;
  height: 30px;
}

.notification .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 7px;
  border-radius: 50%;
  background: red;
  color: white;
}

.bg-green {
  border-top-right-radius: 8px;
  background: #548f4d;
}

.c-white {
  color: white !important;
}
</style>
